import "core-js/modules/es6.string.fixed";
import staticQueryData from "../../public/static/d/4007731267.json";

/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import { StaticQuery } from 'gatsby';
import { Grid, Avatar, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

function Bio() {
  return React.createElement(StaticQuery, {
    query: bioQuery,
    render: function render(data) {
      var _data$site$siteMetada = data.site.siteMetadata,
          author = _data$site$siteMetada.author,
          social = _data$site$siteMetada.social;
      return React.createElement(Grid, {
        container: true,
        alignItems: "center"
      }, React.createElement(Grid, {
        item: true,
        style: {
          marginRight: 12
        }
      }, React.createElement(Avatar, {
        srcSet: data.avatar.childImageSharp.fixed.srcSet
      })), React.createElement(Typography, null, "Written by ", React.createElement("strong", null, author), "."));
    },
    data: staticQueryData
  });
}

var bioQuery = "4007731267";
export default Bio;